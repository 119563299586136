<template>
  <div>
    <div v-for="item in componentsList" :key="item.id">
      <component :is="item.component" v-if="item.id"></component>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      componentsList: [
        {
          id: 1,
          component: () => import("./dongxing.vue"),
        },
      ],
    };
  },
};
</script>